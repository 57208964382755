// Import FilePond plugin code
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';

import FilePondHelper from '../src/FilePondHelper';

window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;
window.FilePond = FilePond;
window.FilePondHelper = FilePondHelper;
