export default function FilePondDownloadLink(filePond) {
  const { addFilter, utils } = filePond;
  const { createRoute } = utils;

  addFilter("CREATE_VIEW", viewAPI => {
    const { is, view, query } = viewAPI;

    if (!is("file")) {
      return;
    }

    const createLinkToFile = ({ root, props }) => {
      const { id } = props;
      const item = query("GET_ITEM", id);

      if (!item || item.archived || !item.file?.url) {
        return;
      }

      const link = $("<a>", {
        href: item.file.url,
        download: true,
        target: "_blank",
        class: "filepond--file-info-main",
      }).
      css({
        "pointer-events": "auto",
        "color": "white",
        "font-weight": "normal",
      }).
      text(item.file.name)

      const el = $(root.element);
      el.find(".filepond--file-info-main").remove();
      el.find(".filepond--file-info").prepend(link);
    };

    view.registerWriter(
      createRoute({
        DID_LOAD_ITEM: createLinkToFile
      })
    );
  });

  return {};
};
