import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondDownloadLink from './downloadLinkPlugin';

export default class FilePondHelper {

  fileTypeOverrides = {
    "msg": "application/vnd.ms-outlook",
  }

  constructor(inputElement, files) {
    this.filePondInstances = [];
    this.allowedTypes = [
      'application/pdf', // PDF
      'application/msword', // Doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Docx
      'text/plain', // TXT
      'application/vnd.ms-excel', // XLS
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //XLXS
      'text/csv', // CSV
      'image/jpeg', // JPEG and JPG
      'image/png', // PNG
      'image/gif', // GIF
      'application/vnd.ms-powerpoint', // PPT
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
      'application/vnd.ms-outlook', // MSG
      'application/vnd.oasis.opendocument.text', //ODT
      'text/rtf', // RTF
      'image/svg+xml', //SVG
      ...Object.keys(this.fileTypeOverrides).map(ext => `.${ext}`)
    ];
    this.initializeFilepondInputs(inputElement, files);
  }

  initializeFilepondInputs(filePondInput, files) {
    FilePond.registerPlugin(
      FilePondPluginFileValidateSize,
      FilePondPluginFileValidateType,
      FilePondDownloadLink
    );

    this.filePondInstances.push(FilePond.create(filePondInput, {
      name: filePondInput.name,
      allowMultiple: true,
      maxFileSize: 10 * 1000 * 1000,
      maxTotalFileSize: 10 * 1000 * 1000,
      fileValidateTypeLabelExpectedTypes: "Use pdf, doc, docx, txt, xls, xlsx, csv, jpeg, jpg, png, gif, ppt, pptx, " +
        "msg, odt, rtf or svg",
      acceptedFileTypes: this.allowedTypes,
      storeAsFile: true,
      fileValidateTypeDetectType: this.mimeTypeDetectorOverride.bind(this),
      disabled: filePondInput.disabled || false,
      files: files || $(filePondInput).data('files'),
      onaddfile: (error, fileItem) => {
        const specialChars =  /[&$+,\/:;=?@<>\[\]\{\}\|\\\^~%#`\n\t\r]/;
        if (specialChars.test(fileItem.file.name)) {
          // Reject the file
          this.filePondInstances[0].removeFile(fileItem.id);
          GF.growl({text: "Please update the file name so that it does not contain these special characters: [ ] { } | ^ ~ % # ` > < & $ + , / : ; = ? @ \\ \\n \\t \\r", title: "Invalid File Name", status: "error"})
        }
      }
    }));
  }

  validateMaxTotalSize() {
    const maxSizeInBytes = 10 * 1000 * 1000; //10 MB
    let totalSize = 0;

    this.filePondInstances[0].getFiles().forEach((file) => {
      totalSize += file.fileSize;
    });

    if (totalSize > maxSizeInBytes) {
      GF.growl({
        text: 'Please resize or remove them before submitting the form.',
        title: 'One or More Files Are Too Large',
        status: 'error'
      });
      throw new Error(
        'Error: One or more files are too large. Please resize or remove them before submitting the form.'
      );
    }
  }

  validateFileType() {
    const files = this.filePondInstances[0].getFiles();

    files.forEach(file => {
      let fileType = file.fileType;
      if (["", "application/x-ole-storage"].includes(fileType)) {
        fileType = this.getFileTypeOverride(file.filename, fileType);
      }
      if (!this.allowedTypes.includes(fileType)) {
        GF.growl({text: "Please upload files of the correct type.", title: "Invalid File Type", status: "error"})
        throw new Error("Error: Invalid file type. Please upload files of the correct type.")
      }
    });
  }

  mimeTypeDetectorOverride(file, detectedType) {
    return new Promise(resolve => resolve(this.getFileTypeOverride(file.name, detectedType)));
  }

  getFileTypeOverride(fileName, detectedType) {
    if ( ["", "application/x-ole-storage"].includes(detectedType) && fileName.includes(".") ) {
      const typeOverride = this.fileTypeOverrides[fileName.split(".").pop()];
      if (typeOverride) return typeOverride;
    }
    return detectedType;
  }
}
